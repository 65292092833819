import React, { useEffect } from "react";
import "../TermsConditions/_terms.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Privacy Policy";
  }, []);

  return (
    <div className="container-fluid mt-lg-5 news-main-wrapper pt-lg-5 px-0 d-flex align-items-center justify-content-center">
      <div className="custom-container  my-lg-0 my-5 py-0 py-lg-5">
        <div className="d-flex w-100 flex-column">
          <div className="row w-100 mx-0 news-container">
            <div className="explorer-grid-title my-4">Privacy Policy</div>
            <div className="news-card terms-container p-4 d-flex flex-column gap-3">
              <p className="text-white m-0">
                Last Revised on December 27, 2022
              </p>
              <p className="text-white m-0">
                We are committed to respecting and protecting your privacy and
                complying with any applicable law and regulation regarding your
                personal information. This Privacy Policy describes how we
                collect, use, disclose, and protect your personal information,
                including across our website https://www.worldofdypians.com/
                (the “Website”), the World of Dypians game (the “Game”), and all
                content and functionalities accessible via our Website and the
                Game (the “Services”), in each case operated by or on behalf of
                World of Dypians (“Company,” “we,” or “us”). Please read this Privacy
                Policy carefully. If you do not agree with this Privacy Policy
                in general or any part of it, you should not access the Website,
                use our Services, or otherwise provide your information to us
              </p>
              <p className="text-white m-0">Information We Collect</p>
              <p className="text-white m-0">
                Information we collect falls into one of two categories:
                “automatically collected” information and “voluntarily provided”
                information.
              </p>
              <p className="text-white m-0">
                “Automatically collected” information refers to any information
                automatically sent by your devices in the course of accessing
                our Services.
              </p>
              <p className="text-white m-0">
                “Voluntarily provided” information refers to any information you
                knowingly and actively provide us when using or participating in
                any of our Services.
              </p>
              <p className="text-white m-0">
                Automatically Collected Information
              </p>
              <p className="text-white m-0">Log Data</p>
              <p className="text-white m-0">
                When you visit our Services, our servers may automatically log
                the standard data provided by your web browser. It may include
                your device’s Internet Protocol (IP) address, geolocation data,
                your browser type and version, the pages you visit, the time and
                date of your visit, the time spent on each page, the referring
                site, website elements clicked, and other details about your
                visit. Additionally, if you encounter certain errors while using
                the site, we may automatically collect data about the error and
                the circumstances surrounding its occurrence. This data may
                include technical details about your device, what you were
                trying to do when the error happened, and other technical
                information relating to the problem. You may or may not receive
                notice of such errors, even in the moment they occur, that they
                have occurred, or what the nature of the error is. Please be
                aware that while this information may not be personally
                identifying by itself, it may be possible to combine it with
                other data to personally identify individual persons.
              </p>
              <p className="text-white m-0">Device Data</p>
              <p className="text-white m-0">
                When you visit our website or interact with our Services, we may
                automatically collect data about your device, such as:
              </p>
              <p className="text-white m-0">Device Type</p>
              <p className="text-white m-0">Operating System</p>
              <p className="text-white m-0">Voluntarily Provided Information</p>
              <p className="text-white m-0">Personal Information</p>
              <p className="text-white m-0">
                We may ask for personal information — for example, when you
                register an account, make a purchase, request support for our
                Services, or when you otherwise contact us, including on Discord
                — which may include one or more of the following:
              </p>
              <p className="text-white m-0">Email address</p>
              <p className="text-white m-0">
                Discord username, if you interact with us there
              </p>
              <p className="text-white m-0">Digital Asset Wallet Address</p>
              <p className="text-white m-0">
                Other information necessary for Anti-Money Laundering (“AML”),
                Counter Financing of Terrorism (“CFT”), and Know Your Customer
                (“KYC”) compliance purposes
              </p>
              <p className="text-white m-0">
                Any other information you choose to provide, such as when you
                fill in a form, file a support ticket, provide screenshots,
                conduct a search, update or add information to your account,
                respond to surveys, participate in promotions, correspond with
                us, or use other features of our Services.
              </p>
              <p className="text-white m-0">Third-Party Information</p>
              <p className="text-white m-0">
                We may combine voluntarily provided and automatically collected
                personal information with information that is publicly available
                (such as on social media) or that you provide to third parties
                that they share with us. For example, we obtain your Digital
                Asset Transaction History, as it relates to Star DYP tokens,
                from your wallet provider.
              </p>
              <p className="text-white m-0">
                Collection and Use of Information
              </p>
              <p className="text-white m-0">
                We may collect personal information from you when you do any of
                the following on our Services:
              </p>
              <p className="text-white m-0">Register for an account</p>
              <p className="text-white m-0">
                Use a mobile device, personal computer, or web browser to access
                our Services
              </p>
              <p className="text-white m-0">
                Contact us via email, social media, chat programs, or on any
                similar technologies
              </p>
              <p className="text-white m-0">
                When you mention us on social media
              </p>
              <p className="text-white m-0">
                We may use information for the following purposes, and personal
                information will not be further processed in a manner that is
                incompatible with these purposes:
              </p>
              <p className="text-white m-0">to provide you with our Services</p>
              <p className="text-white m-0">
                to enable you to customize or personalize your experience of our
                Services
              </p>
              <p className="text-white m-0">
                to operate, improve, and develop our Services
              </p>
              <p className="text-white m-0">
                to contact and communicate with you
              </p>
              <p className="text-white m-0">
                to enable you to access and use our Services, associated
                interactive features, associated applications, and associated
                social media or chat platforms
              </p>
              <p className="text-white m-0">
                to comply with our legal obligations and resolve any disputes
                that we may have
              </p>
              <p className="text-white m-0">
                for security and fraud prevention, and to ensure that our sites
                and apps are safe, secure, and used in line with our Terms of
                Use
              </p>
              <p className="text-white m-0">
                Security of Your Personal Information
              </p>
              <p className="text-white m-0">
                When we collect and process personal information, and while we
                retain this information, we will protect it within commercially
                acceptable means to prevent loss and theft, as well as
                unauthorized access, disclosure, copying, use, or modification.
                Although we will implement and maintain practices to protect the
                personal information you provide to us, we advise that no method
                of electronic transmission or storage is 100% secure, and no one
                can guarantee absolute data security. We recommend that you do
                not use unsecure channels to communicate sensitive or
                confidential information to us. Any information you transmit to
                us you do at your own risk. You are responsible for selecting
                any password, to the extent one is required, and its overall
                security strength, ensuring the security of your own information
                within the bounds of our services. For example, you are
                responsible for ensuring any passwords associated with accessing
                your personal information and accounts are secure and
                confidential.
              </p>
              <p className="text-white m-0">
                How Long We Keep Your Personal Information
              </p>
              <p className="text-white m-0">
                We keep your personal information only for as long as we need
                to. This time period may depend on what we are using your
                information for, in accordance with this Privacy Policy. For
                example, if you have provided us with personal information as
                part of creating an account with us, we may retain this
                information for the duration your account exists on our system.
                If your personal information is no longer required for this
                purpose, we will delete it or make it anonymous by removing all
                details that identify you.
              </p>
              <p className="text-white m-0">Children’s Privacy</p>
              <p className="text-white m-0">
                We do not aim any of our Services directly at children under the
                age of 13, and we do not knowingly collect personal information
                about children under 13. If we become aware that we have
                unknowingly collected information about a child under 13 years
                of age, we will make commercially reasonable efforts to delete
                such information from our database. If you are the parent or
                guardian of a child under 13 years of age who has provided us
                with their personal information, you may contact us using the
                below information to request that it be deleted.
              </p>
              <p className="text-white m-0">
                Disclosure of Personal Information to Third Parties
              </p>
              <p className="text-white m-0">
                We may disclose personal information to third parties in the
                following circumstances:
              </p>
              <p className="text-white m-0">
                with our parent, subsidiary, and/or affiliated entities;
              </p>
              <p className="text-white m-0">
                with service providers for the purpose of enabling them to
                provide our Services, including IT service providers, data
                storage, hosting and server providers, analytics, error loggers,
                debt collectors, maintenance or problem-solving providers,
                professional advisors, and payment systems operators;
                advertising partners;
              </p>
              <p className="text-white m-0">
                our employees, contractors, and/or related entities;
              </p>
              <p className="text-white m-0">
                credit reporting agencies, courts, tribunals, and regulatory
                authorities, in the event you fail to pay for goods or services
                we have provided to you;
              </p>
              <p className="text-white m-0">
                courts, tribunals, regulatory authorities, and law enforcement
                officers, as required by law, or any obligations arising
                thereunder, or in connection with any actual or prospective
                legal proceedings, or in order to establish, exercise, or defend
                our legal rights or those of a third party; to prevent physical
                harm, financial loss, or fraud on our Services; and an entity
                that buys, or to which we transfer, all or substantially all of
                our assets and business.
              </p>
              <p className="text-white m-0">
                Your Rights and Controlling Your Personal Information
              </p>
              <p className="text-white m-0">
                Marketing permission: If you have previously agreed to us using
                your personal information for direct marketing purposes, you may
                change your mind at any time by contacting us using the details
                below.
              </p>
              <p className="text-white m-0">World of Dypians Support</p>
              <p className="text-white m-0">Email: contact@worldofdypians.com</p>
              <p className="text-white m-0">
                Complaints: If you believe that we have breached a relevant data
                protection law and wish to make a complaint, please contact us
                using the details below and provide us with full details of the
                alleged breach. We will promptly investigate your complaint and
                respond to you, in writing, setting out the outcome of our
                investigation and the steps we will take to deal with your
                complaint. You may also have the right to contact a regulatory
                body or data protection authority in relation to your complaint.
              </p>
              <p className="text-white m-0">Where We Store Your Data</p>
              <p className="text-white m-0">
                The personal information that we collect from you will be
                transferred to and stored at/processed in countries inside the
                European Economic Area (“EEA”) and the United Kingdom (“UK”).
                Your personal information is also processed by staff operating
                outside the EEA and the UK who work for us or one of our third
                party service providers or partners. We will take all steps
                reasonably necessary to ensure that your personal information is
                treated securely and in accordance with this Privacy Policy. For
                any transfers of data outside the EEA or the UK, the data
                transfer will be under the European Commission’s model contracts
                for the transfer of personal information to third countries
                (i.e., the standard contractual clauses) (the “Model Clauses”),
                or any equivalent contracts issued by the relevant competent
                authority of the UK, as relevant, unless the data transfer is to
                a country that has been determined by the European Commission or
                the relevant UK authorities, as applicable, to provide an
                adequate level of protection for individuals’ rights and
                freedoms for their personal information.
              </p>
              <p className="text-white m-0">Changes to This Policy</p>
              <p className="text-white m-0">
                At our discretion, we may change our Privacy Policy to reflect
                updates to our business processes, current acceptable practices,
                or legislative or regulatory changes. If we decide to change
                this Privacy Policy, we will post the changes here at the same
                link by which you are accessing this Privacy Policy with the
                last revised date. Updates to our Privacy Policy will apply only
                to information collected after the date of the change. Please
                check the website regularly for notices of changes to our
                Privacy Policy. If the changes are significant, or if required
                by applicable law, we will contact you (based on your selected
                preferences for communications from us) and all our registered
                users with the new details and links to the updated or changed
                policy. If required by law, we will get your permission or give
                you the opportunity to opt in to or opt out of, as applicable,
                any new uses of your personal information.
              </p>
              <p className="text-white m-0">Contact Us</p>
              <p className="text-white m-0">
                Should you have any questions about our privacy practices or
                this Privacy Policy, please email us at contact@worldofdypians.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
