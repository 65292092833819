import React, { useEffect } from "react";
import "./_terms.scss";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Terms of Service";
  }, []);

  return (
    <div className="container-fluid mt-lg-5 pt-lg-5 news-main-wrapper px-0 d-flex align-items-center justify-content-center">
      <div className="custom-container  my-lg-0 my-5 py-0 py-lg-5">
        <div className="d-flex w-100 flex-column">
          <div className="row w-100 mx-0 news-container">
            <div className="explorer-grid-title my-4">Terms of Service</div>
            <div className="news-card terms-container p-4 d-flex flex-column gap-3">
              <p className="text-white m-0">Last Revised on October 01, 2024</p>
              <p className="text-white m-0">
                Welcome to the Terms of Service (these “Terms”) for the website
                https://www.worldofdypians.com/ (the “Website”), the World of
                Dypians game (the “Game”), and all content and functionalities
                accessible via our Website and the Game (the “Services”), in
                each case operated by or on behalf of World of Dypians
                (“Company,” “we,” or “us”).
              </p>
              <p className="text-white m-0">
                These Terms govern your access to and use of the Services.
                Please read these Terms carefully, as they include important
                information about your legal rights. By accessing and/or using
                the Services, you are agreeing to these Terms. If you do not
                understand or agree to these Terms, please do not use the
                Services.
              </p>
              <p className="text-white m-0">
                For purposes of these Terms, “you” and “your” means you as the
                user of the Services. If you use the Services on behalf of a
                company or other entity then “you” includes you and that entity,
                and you represent and warrant that (a) you are an authorized
                representative of the entity with the authority to bind the
                entity to these Terms, and (b) you agree to these Terms on the
                entity's behalf.
              </p>
              <b>
                <p className="text-white m-0"> TABLE OF CONTENTS</p>
              </b>
              <ol>
                <li className="text-white"> SERVICES AND ACCOUNTS </li>
                <li className="text-white">LOCATION OF OUR PRIVACY POLICY</li>
                <li className="text-white">
                  ELIGIBILITY; RIGHTS WE GRANT YOU; USAGE LIMITATIONS
                </li>
                <li className="text-white">OWNERSHIP AND CONTENT</li>
                <li className="text-white">
                  THIRD-PARTY SERVICES AND MATERIALS
                </li>
                <li className="text-white">UPDATES AND PATCHES</li>
                <li className="text-white">TERMINATION</li>
                <li className="text-white">
                  COMPLIANCE WITH SANCTIONS, DISCLAIMERS, LIMITATIONS OF
                  LIABILITY, INDEMNIFICATION
                </li>
                <li className="text-white">ADDITIONAL PROVISIONS</li>
              </ol>
              <p
                className="text-white m-0"
                style={{ textTransform: "capitalize" }}
              >
                1. SERVICES AND ACCOUNTS;
              </p>
              <p className="text-white m-0">
                1.1. Description of the Services. The Services offer a unique
                metaverse gaming experience via the Game and other related
                experiences through the combination of traditional core game and
                blockchain mechanics (the “World of Dypians”). The World of
                Dypians is driven by WOD tokens. WOD serves as the native
                in-Game currency used for in-Game economic interactions, such as
                purchasing resources, items, gear, skins, and other in-Game
                assets. You acknowledge and agree that we cannot be held liable
                or responsible in any way for any damages or losses incurred by
                any person in connection with with any acts or omissions of the
                World of Dypians.
              </p>
              <p className="text-white m-0">
                1.2. DACs. The World of Dypians utilizes a guild system that
                allows you to create factions and collectives that operate in a
                decentralized, autonomous way (“Decentralized Autonomous
                Corporations”). The Services may provide you and other players
                with certain tools that enable the implementation and management
                of such Decentralized Autonomous Corporations. We reserve the
                right to revoke access from any Decentralized Autonomous
                Corporation, or member player thereof, to such tools in the
                event that such Decentralized Autonomous Corporation or player
                violates these Terms or for any other reason we determine in our
                good faith business judgment.
              </p>
              <p className="text-white m-0">
                1.3. Game Items. The Services provide a shop (the “Shop”) for
                you to purchase, transfer, and trade Game-related items such as
                resources, and other items (“Game Items”); provided that any
                Game Items that we indicate are “soulbound” to you shall be
                non-transferable and non-tradable (including with respect to any
                Game NFTs asociated with such Game Items) from the Digital
                Wallet that such Game Items are first stored (“Soulbound
                Items”). Game Items can be used in the Services in various ways
                that we make available, such as to represent in-Game assets that
                you can use while you play the Game. We make no promises or
                guarantees regarding the functionality, utility, or value of any
                Game Items, and such Game Items may evolve and change as we
                update and modify the Game.
              </p>
              <p className="text-white m-0">
                1.4. Game NFTs. Your purchase of Game Items may be accompanied
                by non-fungible tokens (“NFTs”), and your ownership of these
                NFTs is tracked via the specified blockchain (“Game NFTs”). Your
                ownership of these Game NFTs will give you certain rights and
                privileges within the Services, for as long as we make the
                Services and such rights and privileges available to you. You
                are responsible for all payments, fees, and costs when engaging
                in any transactions involving Game NFTs, including, without
                limitation, the purchase price, transaction fees, fees
                associated with your secondary or subsequent sales of Game NFTs,
                and all other fees associated with your use of the Game NFTs via
                the Services.
              </p>
              <p className="text-white m-0">
                1.5. Shop. We provide the Shop to be able to sell Game Items and
                other Services-related offerings (collectively, “Offerings”) to
                you directly from us. The Shop will also facilitate peer-to-peer
                sales conducted via decentralized or third-party exchanges or
                protocols whereby buyers and sellers can buy, sell, and trade
                Game NFTs (other than Soulbound Items) with each other. We may
                charge a transaction fee on all peer-to-peer sales that occur
                via the Shop or that otherwise involve Game NFTs (which may be
                calculated as a percentage of the overall sale price of the Game
                Items). We may offer ways to receive a discount on such
                transaction fees, such as by holding or staking certain
                Services-related tokens and assets. The scope of, and
                pre-requisitions for receiving any, such discount offers are
                subject to change by us in our sole discretion at any time. To
                facilitate transactions via the Shop, we have integrated with
                one or more third-party exchanges and services or other
                decentralized smart contract protocols. These protocols
                facilitate, on a peer-to-peer basis, the exchange of
                cryptocurrency and other assets through the Solana blockchain.
                We do not own or control these third-party or decentralized
                services, we do not control the transfer of cryptocurrency or
                digital assets, including Game NFTs, using these third-party
                services or decentralized services, and we do not have the
                ability to cancel or reverse transactions via the Shop. Except
                as may otherwise be provided in these Terms, we do not control
                or endorse purchases or sales of Game NFTs or other Offerings
                outside of the Services, including the Shop. With respect to
                Game NFTs that are not sold directly by us, we are not a party
                to any agreement entered into between an end user buyer and a
                seller on the Shop. Other than with respect to items sold
                directly by us, we have no control over the conduct of buyers,
                sellers, and any other users of the Shop. We accept no liability
                for any aspect of the buyer and seller peer-to-peer interaction
                on the Shop.
              </p>
              <p className="text-white m-0">
                1.6. Digital Wallet. To use certain of the Services, you need to
                link to the Services via a non-custodial digital wallet made
                available by third parties (a “Third-Party Wallet”), and that is
                compatible with different networks and Services (a “Digital
                Wallet”). A list of supported wallets will be displayed by us,
                such as on the Website. Linking a Digital Wallet to the Services
                is required to be able to purchase, store, and engage in
                transactions using the Services, such as to connect your Game
                NFTs and WOD tokens with the Services. We may not be able to
                help you recover assets associated with your Digital Wallet, as
                these are non-custodial wallets in your control. We do not own
                or control the assets associated with your Digital Wallet. We
                accept no responsibility or liability to you in connection with
                your use of a Digital Wallet and make no representations or
                warranties regarding how the Services will operate with any
                specific Digital Wallet. The private keys, credentials,
                passwords, seed phrases, or secret recovery passphrases
                necessary to decrypt or gain access to a Digital Wallet are held
                solely by you, and not by us. If you choose to transfer items
                from one Digital Wallet to another, such transfers will occur on
                the public blockchain and we accept no liability or
                responsibility for any such transfer, including for any loss,
                data corruption, or other negative impact that may occur to your
                Offerings or Digital Wallets or other assets when attempting to
                transfer assets between Digital Wallets. We reserve the right,
                with or without prior notice and in our sole and complete
                discretion, to discontinue, modify, or limit the operation of
                any Digital Wallet with the Services. You represent and warrant
                that you are the lawful owner of any Digital Wallet and that you
                are using that Digital Wallet under the terms and conditions of
                the applicable provider of the Digital Wallet. To the extent
                that you use a Third-Party Wallet, the following additional
                terms apply: Third-Party Wallets are not associated with,
                maintained by, controlled by, or affiliated with us. If you have
                any issues with your Third-Party Wallet, please contact the
                applicable provider. You represent and warrant that you are the
                lawful owner of any Third-Party Wallet and that you are using
                that Third-Party Wallet under the terms and conditions of the
                applicable provider of the Third-Party Wallet.
              </p>
              <p className="text-white m-0">
                1.7. Promotional Codes. We may offer certain promotional codes,
                referral codes, discount codes, coupon codes, or similar offers
                (“Promotional Codes”) that may be redeemed for discounts on
                future Offerings, or other features or benefits related to the
                Services, subject to any additional terms that the Company
                establishes. You agree that Promotional Codes: (a) must be used
                in a lawful manner; (b) must be used for the intended audience
                and purpose; (c) may not be duplicated, sold, or transferred in
                any manner, or made available by you to the general public
                (whether posted to a public forum, coupon collecting service, or
                otherwise), unless expressly permitted by the Company; (d) may
                be disabled or have additional conditions applied to them by the
                Company at any time for any reason without liability to the
                Company; (e) may only be used pursuant to the specific terms
                that the Company establishes for such Promotional Code; (f) are
                not valid for cash or other credits or points; and (g) may
                expire prior to your use.
              </p>
              <p className="text-white m-0">
                1.8. Changes and Pricing. We may, at any time, revise or change
                the pricing, availability, specifications, content,
                descriptions, or features of any Offerings sold by us, and to
                correct pricing errors that may inadvertently occur (and to
                cancel any orders in our sole discretion that were purchased
                with pricing errors). All such changes shall be effective
                immediately upon posting of such new Offerings prices to the
                Services. While we attempt to be as accurate as we can in our
                descriptions for the Offerings, we do not warrant that Offerings
                descriptions are accurate, complete, reliable, current, or
                error-free. The inclusion of any Offerings for purchase through
                the Services at a particular time does not imply or warrant that
                the Offerings will be available at any other time. We reserve
                the right to change prices for Offerings displayed on the
                Services at any time.
              </p>
              <p className="text-white m-0">
                1.9. Purchases Made for Consumptive Use. You acknowledge and
                affirm that you are acquiring Offerings, whether through the
                Shop or otherwise through the Services, for purposes of use in
                connection with the Services and, in the case of the Game NFTs,
                to play the Game or for other collectible purposes, and in each
                case not for any investment or speculative purposes. Any
                economic benefit that may be derived from appreciation in the
                value of an Offering or Game NFT is incidental to obtaining it
                for its use in connection with the Services or its collectible
                purpose.
              </p>
              <p className="text-white m-0">
                1.10. ALL SALES FINAL. ALL PURCHASES MADE THROUGH THE SERVICES
                ARE FINAL AND NON-REFUNDABLE, EXCEPT AS REQUIRED BY APPLICABLE
                LAW OR REGULATION (AND IN SUCH INSTANCES WHERE REQUIRED BY
                APPLICABLE LAW, THE COMPANY’S ABILITY TO ISSUE ANY SUCH REFUND
                IS LIMITED TO WHETHER THE APPLICABLE SMART CONTRACT ENABLES THE
                COMPANY TO REASONABLY REVERSE OR CANCEL A TRANSACTION).
                NEVERTHELESS, YOU ACKNOWLEDGE THAT THE COMPANY DOES NOT IN MOST
                CASES HAVE THE ABILITY TO REVERSE OR CANCEL A TRANSACTION AND IS
                NOT REQUIRED OR OBLIGATED TO PROVIDE A REFUND OF ANY PURCHASE
                FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER
                COMPENSATION FOR TIME, ITEMS, AMOUNTS, OR VALUES IN A GAME
                ACCOUNT (INCLUDING ANY UNUSED OR PARTIAL USED MEMBERSHIP TIME),
                EVEN IF AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY
                OR INVOLUNTARY.
              </p>
              <p className="text-white m-0">
                2. LOCATION OF OUR PRIVACY POLICY
              </p>
              <p className="text-white m-0">
                2.1. Privacy Policy. Our Privacy Policy describes how we handle
                the information you provide to us when you use the Services. For
                an explanation of our privacy practices, please visit our
                Privacy Policy located at
                https://worldofdypians.com/privacy-policy.
              </p>
              <p className="text-white m-0">
                3. ELIGIBILITY; RIGHTS WE GRANT YOU; USAGE LIMITATIONS
              </p>
              <p className="text-white m-0">
                3.1. Eligibility. You must be 13 years of age or older to use
                the Services. Additional aspects of the Services may have
                further age restrictions that will be presented to you in
                connection with those Services. Minors under the age of majority
                in their jurisdiction but that are at least 13 years of age are
                only permitted to use the Services if the minor’s parent or
                guardian or other holder of parental authority accepts these
                Terms on the minor’s behalf prior to use of the Services.
                Children under the age of 13 are not permitted to use the
                Services. Further, if you use the Services, you may not transfer
                or provide to us any currency, digital assets, or other items
                that have been derived from any illegal or unlawful activity. By
                using the Services, you represent and warrant that you meet
                these all requirements.
              </p>
              <p className="text-white m-0">
                3.2. User Account. Though your linked Digital Wallet will
                function as a unique identifier for you in respect of your use
                of the Services, we may additionally require you to create an
                account in order to use certain portions of the Services (to the
                extent applicable, your “Account”). To the extent that we
                require you to create an Account, we will provide you with
                instructions on how to do so, and once created, how to access,
                edit, and update your Account. In your creation, editing, or
                updating of any such Account, you agree to provide us with
                accurate, complete, and updated information. You are solely
                responsible for any activity on your Account and for maintaining
                the confidentiality and security of your password. We are not
                liable for any acts or omissions by you in connection with your
                Account.
              </p>
              <p className="text-white m-0">3.3. License Grant</p>
              <p className="text-white m-0">
                a. Subject to your compliance with these Terms, the Company
                hereby grants to you, a personal, worldwide, royalty-free,
                non-assignable, non-sublicensable, non-transferrable, and
                non-exclusive license to access and use the software provided to
                you as part of the Services (and, to the extent we offer you any
                software for download as part of the Services (such as the Game
                client), to download, install, and execute a single copy of such
                software onto the equipment or device specified or approved by
                us). This license has the sole purpose of enabling you to use
                and enjoy the benefit of the Services as provided by us, in the
                manner permitted by these Terms, and subject to the use
                restrictions described below. Your access and use of the
                Services may be interrupted from time to time for any of several
                reasons, including, without limitation, the malfunction of
                equipment, periodic updating, maintenance, or repair of the
                Services or other actions that Company, in its sole discretion,
                may elect to take.
              </p>
              <p className="text-white m-0">
                b. Subject to your compliance with these Terms, the Company
                hereby grants to you, a personal, worldwide, royalty-free,
                non-assignable, non-sublicensable, non-transferrable, and
                non-exclusive license to access and use the software provided to
                you as part of the Services (and, to the extent we offer you any
                software for download as part of the Services (such as the Game
                client), to download, install, and execute a single copy of such
                software onto the equipment or device specified or approved by
                us). This license has the sole purpose of enabling you to use
                and enjoy the benefit of the Services as provided by us, in the
                manner permitted by these Terms, and subject to the use
                restrictions described below. Your access and use of the
                Services may be interrupted from time to time for any of several
                reasons, including, without limitation, the malfunction of
                equipment, periodic updating, maintenance, or repair of the
                Services or other actions that Company, in its sole discretion,
                may elect to take.
              </p>
              <p className="text-white m-0">
                c. When you purchase a Game NFT, you own the NFT as a token on
                the blockchain, but you do not own any intellectual property
                rights in the related Content associated with such NFT except
                for the license grants expressly set forth herein. If you
                purchase or otherwise lawfully acquire a Game NFT, then subject
                to your compliance with these Terms, the Company hereby grants
                you a worldwide, non-exclusive, non-transferable, royalty-free
                license to use, copy, and display the Content that is
                specifically associated with such purchased Game NFT, and solely
                for the following purposes: (a) for your own non-commercial and
                not-for-profit use within or related to the Services and not
                otherwise in conflict with these Terms or any other
                Services-related policies (e.g., not to create and sell
                merchandise related to such Content); (b) as part of the Shop
                and any other Shop that permits the purchase and sale of your
                Game NFTs, provided that the Shop cryptographically verifies
                that only the actual owner can display the Content in connection
                with their sale of their Game NFT; or (c) as part of a
                third-party website or application that permits the display,
                inclusion, involvement, or participation of your Game NFT,
                provided that the website/application cryptographically verifies
                each Game NFT’s ownership of the Game NFT to ensure that only
                the actual owner can display the Content, and provided that the
                Content is no longer visible once the owner of the Game NFT
                leaves the website/application. This license only lasts as long
                as you are the valid owner and holder of the Game NFT associated
                with the licensed Content. If you sell or transfer the Game NFT
                to another person, this license will transfer to such other
                owner or holder of the Game NFT, and you will no longer have the
                benefits of such license. All rights not expressly granted are
                reserved.
              </p>
              <p className="text-white m-0">
                3.4. Restrictions On Your Use of the Services. You may not do,
                or assist others in doing, any of the following, unless
                applicable laws or regulations prohibit these restrictions or
                you have our written permission to do so:
              </p>
              <p className="text-white m-0">
                a. download, modify, copy, distribute, transmit, translate,
                display, perform, reproduce, duplicate, publish, license, create
                derivative works from, or offer for sale any Content or other
                materials or information contained on, or obtained from or
                through, the Services, provided, however, that such activities
                are permitted strictly for non-commercial and not-for-profit
                uses;
              </p>
              <p className="text-white m-0">
                b. duplicate, decompile, reverse engineer, disassemble, decode
                or derive source code from the Services (including any
                underlying idea or algorithm), or attempt to do any of the same,
                except if we have permitted you to do so (such as under an open
                source license we have granted);
              </p>
              <p className="text-white m-0">
                c. use, reproduce, or remove any copyright, trademark, service
                mark, trade name, slogan, logo, image, or other proprietary
                notation displayed by Company on or through the Services,
                including to promote, advertise, market, or sell any third-party
                product or service or merchandise, or for any other purpose.
              </p>
              <p className="text-white m-0">
                d. use, offer, promote, advertise, make available, and/or
                distribute the following or assist therein:
              </p>
              <p className="text-white m-0">
                (i) cheats (e.g., methods, not expressly authorized by the
                Company to influence and/or facilitate the gameplay in an unfair
                manner, including exploits of any in-Game bugs, and thereby
                granting you and/or any other user an advantage over other
                players not using such methods),
              </p>
              <p className="text-white m-0">
                (ii) automation software (bots) (i.e., any code and/or software,
                not expressly authorized by the Company, that allows the
                automated control of a Game or any other feature of the
                Services, e.g., the automated control of a character in a Game),
              </p>
              <p className="text-white m-0">
                (iii) hacks (i.e., accessing or modifying the software of the
                Services in any manner not expressly authorized by the Company),
              </p>
              <p className="text-white m-0">
                (iv) modifications (mods) not expressly authorized by the
                Company, or
              </p>
              <p className="text-white m-0">
                (v) any other code and/or software, not expressly authorized by
                the Company, including third-party software, that can be used in
                connection with the Services and/or any component or feature
                thereof which changes and/or facilitates the gameplay or other
                functionality;
              </p>
              <p className="text-white m-0">
                e. engage in any manipulative activity that violates the
                integrity of the prices of assets on the Shop, including (but
                not limited to) the manipulative tactics commonly known as
                spoofing and wash trading;
              </p>
              <p className="text-white m-0">
                f. access or use the Services in any manner that could disable,
                overburden, damage, disrupt, or impair the Services or interfere
                with any other party’s access to or use of the Services or use
                any device, software, or routine that causes the same;
              </p>
              <p className="text-white m-0">
                g. attempt to gain unauthorized access to, interfere with,
                damage, or disrupt the Services, accounts registered to other
                users, or the computer systems or networks connected to the
                Services;
              </p>
              <p className="text-white m-0">
                h. circumvent, remove, alter, deactivate, degrade, or thwart any
                technological measure or content protections of the Services;
              </p>
              <p className="text-white m-0">
                i. use any unauthorized robot, spider, crawlers, or other
                automatic device, process, software, or queries that intercepts,
                “mines,” scrapes, or otherwise accesses the Services to monitor,
                extract, copy, or collect information or data from or through
                the Services, or engage in any manual process to do the same,
                provided, however, that the Company may, at its sole and
                absolute discretion, allow the use of certain third-party user
                interfaces;
              </p>
              <p className="text-white m-0">
                j. exploit a design flaw, undocumented problem, or program bug
                in the Services, including but not limited to creating, using,
                or transacting with any in-Game item created or copied by such
                exploit;
              </p>
              <p className="text-white m-0">
                k. host, provide, or develop matchmaking services for the
                Game(s), or intercept, emulate, or redirect the communication
                protocols used by the Company in any way, for any purpose,
                including without limitation unauthorized play over the
                internet, network play (except as expressly authorized by the
                Company), or as part of content aggregation networks;
              </p>
              <p className="text-white m-0">
                l. facilitate, create, or maintain any unauthorized connection
                to the Services including without limitation (i) any connection
                to any unauthorized server that emulates, or attempts to
                emulate, the Services; and (ii) any connection using third-party
                programs or tools not expressly authorized by the Company;
              </p>
              <p className="text-white m-0">
                m. allow another party/person/entity access to your account
                information or game session or otherwise share your account;
              </p>
              <p className="text-white m-0">
                n. attempt to sell, sublicense, rent, lease, grant a security
                interest in, or otherwise transfer any copy of the Services, any
                component thereof, or your rights to the Services to any other
                party in any way not expressly authorized herein;
              </p>
              <p className="text-white m-0">
                o. introduce any viruses, trojan horses, worms, logic bombs, or
                other materials that are malicious or technologically harmful
                into our systems;
              </p>
              <p className="text-white m-0">
                p. use the Services for illegal, harassing, unethical, or
                disruptive purposes, including engaging in any conduct intended
                to disrupt or diminish the game experience for other players or
                disrupt operation of the Company’s Services in any way,
                including:
              </p>
              <p className="text-white m-0">
                (i) disrupting or assisting in the disruption of any computer
                used to support the Services or any Game environment, or
              </p>
              <p className="text-white m-0">
                (ii) harassment, “griefing,” abusive behavior or chat, conduct
                intended to unreasonably undermine or disrupt the Game
                experiences of others, deliberate inactivity, or disconnecting;
              </p>
              <p className="text-white m-0">
                q. use the Services in such a way as to infringe the privacy,
                intellectual property rights, or other rights of third parties;
              </p>
              <p className="text-white m-0">
                r. violate any applicable law or regulation in connection with
                your access to or use of the Services; or
              </p>
              <p className="text-white m-0">
                s. access or use the Services in any way not expressly permitted
                by these Terms.
              </p>
              <p className="text-white m-0">
                3.5. Prohibited Activities. Users are strictly prohibited from
                creating, using, or maintaining multiple accounts for the
                purpose of obtaining monetary benefits, including but not
                limited to rewards, bonuses, or other financial incentives
                offered within the Services. Any attempt to manipulate the
                system by creating duplicate accounts, engaging in fraudulent
                activities, or exploiting the platform's reward mechanisms will
                result in account restrictions, suspension, forfeiture of earned
                benefits, and potential legal action where applicable. We
                reserve the right to investigate and take necessary actions,
                including the termination of accounts found in violation of this
                policy, at our sole discretion.
              </p>
              <p className="text-white m-0">
                3.6. Use of the Services. You are responsible for providing the
                computer, mobile device, wireless service plan, software,
                Internet connections, and/or other equipment or services that
                you need to download, install, and use any aspect of the
                Services. We do not guarantee that any aspect of the Services
                can be accessed and used on any particular device or with any
                particular service plan. We do not guarantee that any aspect of
                the Services or will be available in, or that orders for
                Offerings can be placed from, any particular geographic
                location.
              </p>
              <p className="text-white m-0">3.7. Monitoring.</p>
              <p className="text-white m-0">
                a. WHILE RUNNING, THE SERVICES (INCLUDING THE GAME) AND COMPANY
                MAY MONITOR YOUR COMPUTER OR MOBILE DEVICE’S MEMORY FOR
                UNAUTHORIZED THIRD-PARTY PROGRAMS RUNNING EITHER CONCURRENTLY
                WITH A GAME OR OUT OF PROCESS. AN “UNAUTHORIZED THIRD-PARTY
                PROGRAM” AS USED HEREIN SHALL BE DEFINED AS ANY THIRD-PARTY
                SOFTWARE PROHIBITED BY THE “RESTRICTIONS ON YOUR USE OF THE
                SERVICES” SECTION ABOVE. IN THE EVENT THAT THE SERVICES DETECT
                AN UNAUTHORIZED THIRD-PARTY PROGRAM (a) THE SERVICES MAY
                COMMUNICATE INFORMATION BACK TO THE COMPANY, INCLUDING WITHOUT
                LIMITATION YOUR ACCOUNT NAME, WALLET ADDRESS, DETAILS ABOUT THE
                UNAUTHORIZED THIRD-PARTY PROGRAM DETECTED, AND THE TIME AND
                DATE; AND/OR (b) THE COMPANY MAY EXERCISE ANY OR ALL OF ITS
                RIGHTS UNDER THESE TERMS, WITH OR WITHOUT PRIOR NOTICE TO YOU.
              </p>
              <p className="text-white m-0">
                b. Additionally, the Services may include a tool that will allow
                your computer system to forward information to the Company in
                the event of a crash of any aspect of the Services, including
                system and driver data, and by agreeing hereto you consent to
                the Company receiving and/or using this data.
              </p>
              <p className="text-white m-0">
                c. If the Services detect the use of an Unauthorized Third-Party
                Program, your access to the Services may be terminated with or
                without additional notice to you.
              </p>
              <p className="text-white m-0">4. OWNERSHIP AND CONTENT</p>
              <p className="text-white m-0">
                4.1. Ownership of the Services. The Services, including their
                “look and feel” (e.g., text, graphics, user interface, design,
                images, logos, icons, software), Content (including Game
                audiovisual assets and artwork), data, information, and other
                materials, are protected under copyright, trademark, and other
                intellectual property laws. You agree that the Company and/or
                its licensors own all right, title, and interest in and to the
                Services (including any and all intellectual property rights
                therein) and you agree not to take any action(s) inconsistent
                with such ownership interests. We and our licensors reserve all
                rights in connection with the Services and its content (other
                than Your Content, defined below), including, without
                limitation, the exclusive right to create derivative works.
              </p>
              <p className="text-white m-0">
                4.2. Ownership of Trademarks. The Company’s name, World of
                Dypians, the Company’s stylized “A” logo, and all related names,
                logos, product and service names, designs, and slogans are
                trademarks of the Company or its affiliates or licensors. Other
                names, logos, product and service names, designs, and slogans
                that appear on the Services are the property of their respective
                owners, who may or may not be affiliated with, connected to, or
                sponsored by us.
              </p>
              <p className="text-white m-0">
                4.3. Feedback. We welcome feedback, bug reports, comments, and
                suggestions for improvements to the Services, but please do not
                send suggstions for creative ideas, design pitch portfolios, or
                similiar such meterials (“Unsolicited Ideas”). We may currently
                be developing, have developed, or in the future will develop
                ideas or materials interally or recieve ideas or materials from
                other parties that may purely by coincidence be similar to
                Unsolicited Ideas. If you ignore this policy and send us your
                Unsolicited Ideas anyway, you grant the Company a non-exclusive,
                worldwide, perpetual, irrevocable, fully-paid, royalty-free,
                sublicensable, and transferable license under any and all
                intellectual property or other rights that you own or control to
                use, copy, modify, create derivative works based upon, make,
                have made, sell, offer for sale, import, and otherwise exploit
                in any manner or medium whatsoever known now or in the future
                your Unsolicited Ideas for any purpose, without compensation to
                you.
              </p>
              <p className="text-white m-0">
                4.4. Your Content License Grant. In connection with your use of
                the Services, you may be able to post, upload, or submit content
                to be made available through the Services (“Your Content”), such
                as user profiles, comments, user avatars, guild names, sound
                files, images, music files, game assets, and other user
                generated content. In order to operate the Services, we must
                obtain from you certain license rights in Your Content so that
                actions we take in operating the Services are not considered
                legal violations. Accordingly, by using the Services and
                uploading Your Content, you grant us a license to access, use,
                host, cache, store, reproduce, transmit, display, publish,
                distribute, and modify (for technical purposes, e.g., making
                sure content is viewable on smartphones as well as computers and
                other devices) Your Content to be able to operate and provide
                the Services. You agree that these rights and licenses are
                royalty free, transferable, sub-licensable, worldwide, and
                irrevocable (for so long as Your Content is stored with us), and
                include a right for us to make Your Content available to, and
                pass these rights along to, others with whom we have contractual
                relationships related to the provision of the Services, solely
                for the purpose of providing such Services, and to otherwise
                permit access to or disclose Your Content to third parties if we
                determine such access is necessary to comply with our legal
                obligations. As part of the foregoing license grant you agree
                that the other users of the Services shall have the right to
                comment on and/or tag Your Content and/or to use, publish,
                display, modify, or include a copy of Your Content as part of
                their own use of the Services; except that the foregoing shall
                not apply to any of Your Content that you post privately for
                non-public display on the Services. By posting or submitting
                Your Content through the Services, you represent and warrant
                that you have, or have obtained, all rights, licenses, consents,
                permissions, power, and/or authority necessary to grant the
                rights granted herein for Your Content. You agree that Your
                Content will not contain material subject to copyright or other
                proprietary rights, unless you have the necessary permission or
                are otherwise legally entitled to post the material and to grant
                us the license described above.
              </p>
              <p className="text-white m-0">
                5. THIRD-PARTY SERVICES AND MATERIALS
              </p>
              <p className="text-white m-0">
                5.1. Use of Third-Party Materials in the Services. Certain
                Services may display, include, or make available content, data,
                information, applications, or materials from third parties
                (“Third-Party Materials”) or provide links to certain
                third-party websites. By using the Services, you acknowledge and
                agree that the Company is not responsible for examining or
                evaluating the content, accuracy, completeness, availability,
                timeliness, validity, copyright compliance, legality, decency,
                quality, or any other aspect of such Third-Party Materials or
                websites. We do not warrant or endorse and do not assume and
                will not have any liability or responsibility to you or any
                other person for any third-party services, Third-Party
                Materials, or third-party websites, or for any other materials,
                products, or services of third parties. Third-Party Materials
                and links to other websites are provided solely as a convenience
                to you.
              </p>
              <p className="text-white m-0">6. UPDATES AND PATCHES</p>
              <p className="text-white m-0">
                6.1. The Company (or a third party on the Company’s behalf) may
                deploy or provide patches, updates, and modifications to the
                Services that must be installed for you to continue to use the
                Services. The Company may update the Services remotely,
                including, without limitation, any aspect of the Services
                residing on your computer or game machine, without your
                knowledge or consent, and you hereby grant to the Company your
                consent to deploy and apply such patches, updates, and
                modifications as the Company, in its sole discretion, deems
                necessary or advisable. You also agree that the Company may
                change, modify, suspend, “nerf,” “buff,” discontinue, or
                restrict your access to any features or parts (including but not
                limited to Game Items, ships, structures, resources, regions,
                factions, or lore) of the Services at any time without notice or
                liability to you.
              </p>
              <p className="text-white m-0">7. TERMINATION</p>
              <p className="text-white m-0">
                7.1. You acknowledge and agree that the Company, in its sole and
                absolute discretion, may (i) stop providing support for or
                access to the Services at any time, for any reason or no reason,
                and (ii) terminate your right to use the Services and terminate
                these Terms immediately at any time without notice or liability
                to you.
              </p>
              <p className="text-white m-0">
                7.2. In the event of termination of these Terms or your rights
                or license granted hereunder, you must (i) cease to use the
                Services; (ii) immediately and permanently remove from all of
                your devices all aspects of the Services in your possession and
                control. Upon termination of these Terms for any reason, all
                licenses granted herein immediately shall terminate.
              </p>
              <p className="text-white m-0">
                8. COMPLIANCE WITH SANCTIONS, DISCLAIMERS, LIMITATIONS OF
                LIABILITY, INDEMNIFICATION
              </p>
              <p className="text-white m-0">
                8.1. By using our Services, you represent and warrant that: (a)
                neither you, as an individual or entity, nor any entity or
                individual you represent or are affiliated with, are subject to
                any sanctions imposed by the U.S. Government (including the
                Office of Foreign Assets Control of the U.S. Department of the
                Treasury ("OFAC")), the United Nations Security Council, the
                European Union, Her Majesty's Treasury, or any other relevant
                sanctions authority; (b) neither you nor any entity or
                individual you represent or are affiliated with, are located,
                organized, or resident in a country or territory that is the
                subject or target of sanctions imposed by any of the
                aforementioned authorities; and (c) neither you, as an
                individual or entity, nor your wallet address, appear on the
                Specially Designated Nationals and Blocked Persons List,
                Consolidated Sanctions List, or any other sanctions lists
                administered by OFAC or the other listed authorities (d) you are
                not using the Services for money laundering or any illegal
                activity; (e) you have not engaged in fraudulent activity; (f)
                you have not acquired cryptoassets using inappropriate methods,
                including the use of stolen funds to purchase such assets. You
                further agree not to use our Services for any activities that
                would result in a violation of such sanctions, including but not
                limited to engaging with any person or entity subject to
                sanctions, or conducting business in any country or territory
                that is the subject of sanctions, including Crimea, Russia,
                Cuba, Iran, North Korea, Côte d'Ivoire, Belarus, Iraq, Liberia,
                Sudan and Syria. We reserve the right to take whatever action we
                deem appropriate, including to terminate or restrict access to
                our Services, if we reasonably suspect any violation of
                applicable sanctions laws or regulations. You agree to indemnify
                and hold harmless the Company from any liability arising from
                your breach of this clause.
              </p>

              <p className="text-white m-0">
                8.2. DISCLAIMERS. YOUR ACCESS TO AND USE OF THE SERVICES ARE AT
                YOUR OWN RISK. YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE
                PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT
                LIMITING THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED UNDER
                APPLICABLE LAW, THE COMPANY, ITS PARENTS, AFFILIATES, RELATED
                COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
                REPRESENTATIVES, PARTNERS, AND LICENSORS (THE “COMPANY
                ENTITIES”) DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER
                EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, AND NON-INFRINGEMENT. THE COMPANY ENTITIES MAKE NO
                WARRANTY OR REPRESENTATION AND DISCLAIM ALL RESPONSIBILITY AND
                LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY, AVAILABILITY,
                TIMELINESS, SECURITY, OR RELIABILITY OF THE SERVICES; (B) ANY
                HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT
                RESULTS FROM YOUR ACCESS TO OR USE OF THE SERVICES; (C) THE
                OPERATION OR COMPATIBILITY WITH ANY OTHER APPLICATION OR ANY
                PARTICULAR SYSTEM OR DEVICE; (D) WHETHER THE SERVICES WILL MEET
                YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE,
                OR ERROR-FREE BASIS; AND (E) THE DELETION OF, OR THE FAILURE TO
                STORE OR TRANSMIT, YOUR CONTENT, DATA, AND OTHER COMMUNICATIONS
                MAINTAINED BY THE SERVICES. NO ADVICE OR INFORMATION, WHETHER
                ORAL OR WRITTEN, OBTAINED FROM THE COMPANY ENTITIES OR THROUGH
                THE SERVICES, WILL CREATE ANY WARRANTY OR REPRESENTATION NOT
                EXPRESSLY MADE HEREIN. FOR CLARITY, WE HAVE NO CONTROL OVER AND
                MAKE NO GUARANTEES OR PROMISES WITH RESPECT TO THE FUNCTIONALITY
                OF THE SOLANA BLOCKCHAIN AND/OR THE EXECUTION OF SMART CONTRACTS
                AND/OR TRANSACTIONS THEREIN.
              </p>
              <p className="text-white m-0">
                8.3. LIMITATIONS OF LIABILITY. TO THE MAXIMUM EXTENT NOT
                PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE COMPANY
                ENTITIES BE LIABLE FOR ANY INDIRECT, SPECIAL, EXEMPLARY,
                INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING, BUT
                NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES,
                LOSS OF USE, DATA OR PROFITS, BUSINESS INTERRUPTION, OR ANY
                OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE
                OR INABILITY TO USE THE SERVICES), HOWEVER CAUSED AND UNDER ANY
                THEORY OF LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE
                ARISING IN ANY WAY IN CONNECTION WITH THE SERVICES OR THESE
                TERMS AND WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
                (INCLUDING NEGLIGENCE OR OTHERWISE) EVEN IF THE COMPANY ENTITIES
                HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. SOME
                JURISDICTIONS (SUCH AS THE STATE OF NEW JERSEY) DO NOT ALLOW THE
                EXCLUSION OR LIMITATION OF CERTAIN INCIDENTAL OR CONSEQUENTIAL
                DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION MAY NOT APPLY TO
                YOU IF YOU ARE A RESIDENT OF THOSE JURISDICTIONS. THE COMPANY
                ENTITIES’ TOTAL LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED
                SHALL NOT EXCEED THE GREATER OF (I) THE AMOUNT YOU PAID THE
                COMPANY ENTITIES, IF ANY, IN THE PAST SIX (6) MONTHS FOR THE
                SERVICES (OR OFFERINGS PURCHASED ON THE SERVICES) GIVING RISE TO
                THE CLAIM, OR (II) $100 USD. THE FOREGOING LIMITATIONS WILL
                APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL
                PURPOSE.
              </p>
              <p className="text-white m-0">
                8.4. Indemnification. By entering into these Terms and accessing
                or using the Services, you agree that you shall defend,
                indemnify, and hold the Company Entities harmless from and
                against any and all claims, costs, damages, losses, liabilities,
                and expenses (including attorneys’ fees and costs) incurred by
                the Company Entities arising out of or in connection with: (a)
                your violation or breach of any term of these Terms or any
                applicable law or regulation; (b) your violation of any rights
                of any third party; (c) your access to or use of the Services;
                (d) Your Content; or (e) your negligence or willful misconduct.
              </p>
              <p className="text-white m-0">
                8.5. Assumption of Risks. You acknowledge and agree that there
                are risks associated with purchasing and holding NFTs and using
                blockchain technology. These include, but are not limited to,
                risk of losing access to NFTs due to loss of a PIN, wallet keys,
                seed phrases, two-factor authentication devices, or log-in
                information, user error when transferring NFTs, custodial error
                or purchaser error, smart contract error, risk of blockchain
                attacks, risk of hacking and security weaknesses, risk of
                unfavorable regulatory intervention in one or more
                jurisdictions, risks related to token taxation, risk of personal
                information disclosure, risk of uninsured losses, unanticipated
                risks, and volatility risks. You acknowledge that you have
                obtained sufficient information to make an informed decision to
                purchase a Game NFT, including carefully reviewing the code of
                the smart contract and the NFT, and fully understand and accept
                the functions of the same. The regulatory regime governing
                blockchain technologies, cryptocurrencies, and tokens is
                uncertain, and new regulations or policies may materially
                adversely affect the development of the Services, and therefore
                the potential utility or value of your Game NFTs. Upgrades to
                any blockchain network or hard forks in such networks, or a
                change in how transactions are confirmed on such blockchain
                networks, may have unintended, adverse effects on all
                blockchains, including any that are related to your Game NFTs.
              </p>
              <p className="text-white m-0">
                8.6. Taxes. You are solely responsible to pay any and all sales,
                use, value-added, and other taxes, duties, and assessments
                (except taxes on our net income) now or hereafter claimed or
                imposed by any governmental authority associated with your use
                of the Services. Except for income taxes levied on us, you: (a)
                will be solely responsible for reporting any tax obligations
                when, if ever, such obligations arise as a result of your use of
                the Services or in relation to an Offering; (b) will pay or
                reimburse Company for all national, federal, state, local, or
                other taxes and assessments of any jurisdiction, including value
                added taxes and other taxes as may be required, and amounts
                levied in lieu thereof based on charges set, services performed,
                or payments made hereunder, as are now or hereafter may be
                imposed under the authority of any national, state, local, or
                any other taxing jurisdiction; and (c) will not be entitled to
                deduct the amount of any such taxes, duties, or assessments from
                payments (including transaction fees) made to us pursuant to
                these Terms. Neither these Terms nor any other communication
                from the Company constitutes tax advice, and users are solely
                responsible for determining what, if any, taxes apply to their
                interaction with Offerings and the Services.
              </p>
              <p className="text-white m-0">
                8.7. Force Majeure. We will not be liable or responsible to you,
                nor be deemed to have defaulted under or breached these Terms,
                for any failure or delay in fulfilling or performing any of our
                obligations under these Terms or in operating the Services, when
                and to the extent such failure or delay is caused by or results
                from any events beyond the Company’s ability to control,
                including acts of God, flood, fire, earthquake, epidemics,
                pandemics, quarantine restrictions, tsunami, explosion, war,
                invasion, hostilities (whether war is declared or not),
                terrorist threats or acts, riot or other civil unrest,
                government order, law, or action, embargoes or blockades,
                strikes, labor stoppages or slowdowns or other industrial
                disturbances, shortage of adequate or suitable Internet
                connectivity, telecommunication breakdown, shortage of adequate
                power or electricity, and other similar events beyond our
                control.
              </p>

              <p className="text-white m-0">9. ADDITIONAL PROVISIONS</p>
              <p className="text-white m-0">
                9.1. Updating These Terms. We may modify these Terms from time
                to time in which case we will update the “Last Revised On” date
                at the top of these Terms. If we make changes that are material,
                we will use reasonable efforts to attempt to notify you.
                However, it is your sole responsibility to review these Terms
                from time to time to view any such changes. The updated Terms
                will be effective as of the time of posting, or such later date
                as may be specified in the updated Terms. Your continued access
                or use of the Services after the modifications have become
                effective will be deemed your acceptance of the modified Terms.
              </p>
              <p className="text-white m-0">
                9.2. Termination of License and Your Account. If you breach any
                of the provisions of these Terms, all licenses granted by the
                Company will terminate automatically. Additionally, the Company
                may suspend, disable, or delete your Account and/or the Services
                (or any part of the foregoing) with or without notice, for any
                or no reason. If the Company deletes your Account for any
                suspected breach of these Terms by you, you are prohibited from
                re-registering for the Services under a different name or wallet
                address. In the event of Account deletion for any reason, the
                Company may, but is not obligated to, delete any of Your
                Content. The Company shall not be responsible for the deletion
                of or failure to delete Your Content. All sections which by
                their nature should survive the termination of these Terms shall
                continue in full force and effect subsequent to and
                notwithstanding any termination of these Terms by the Company or
                you. Termination will not limit any of the Company’s other
                rights or remedies at law or in equity.
              </p>
              <p className="text-white m-0">
                9.3. Injunctive Relief. You agree that a breach of these Terms
                will cause irreparable injury to the Company for which monetary
                damages would not be an adequate remedy and the Company shall be
                entitled to equitable relief in addition to any remedies it may
                have hereunder or at law without a bond, other security, or
                proof of damages.
              </p>
              <p className="text-white m-0">
                9.4. Miscellaneous. If any provision of these Terms shall be
                unlawful, void, or for any reason unenforceable, then that
                provision shall be deemed severable from these Terms and shall
                not affect the validity and enforceability of any remaining
                provisions. These Terms and the licenses granted hereunder may
                be assigned by the Company but may not be assigned by you
                without the prior express written consent of the Company. No
                waiver by either party of any breach or default hereunder shall
                be deemed to be a waiver of any preceding or subsequent breach
                or default. The section headings used herein are for reference
                only and shall not be read to have any legal effect. The
                Services are operated by us in the United States. Those who
                choose to access the Services from locations outside the United
                States do so at their own initiative and are responsible for
                compliance with applicable local laws.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
